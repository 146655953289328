@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.reservation-modal {

  &.success {
    .ant-modal-content {
      .ant-modal-close-x {
        svg {
          path {
            fill: white;
          }
        }
      }
    }
    .ant-modal-header {
      background-color: #70D06C !important;
      .ant-modal-title {
        color: white !important;
      }
    }
  }
  &.error {
    .ant-modal-content {
      .ant-modal-close-x {
        svg {
          path {
            fill: white;
          }
        }
      }
    }
    .ant-modal-header {
      background-color: #F0452A !important;
      .ant-modal-title {
        color: white !important;
      }
    }
  }

  &.confirm-btn-disable {
    .ant-modal-footer {
      button {
        &.ant-btn-primary {
          background-color: #EFEFF0 !important;
        }
      }
    }
  }

  .ant-modal-content {

    .ant-modal-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 10px;
      .custom-input-group {
        width: calc(50% - 5px);
        margin-bottom: 20px;
      }
    }

    .ant-modal-close-x {
      height: 70px;
      line-height: 70px;
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: #202020;
        }
      }
    }
    .ant-modal-header {
      padding: 24px;
      background-color: $brandColor;
      border-bottom: none;

      .ant-modal-title {
        font-size: 16px;
        line-height: 22px
      }
    }

    .ant-modal-footer {
      border-top: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px 40px;
      button {
        width: calc(100% - 5px);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        font-size: 17px;
        color: #000;
        border: none;
        text-transform: capitalize;
        transition: all 0.5s ease 0s;
        letter-spacing: 1px;
        font-weight: 500;
        white-space: nowrap;

        &:first-child {
          color: #202020;
          border-radius: unset!important;
          background: #EFEFF0 !important;
          font-size: 13px;
          line-height: 18px;
          &:hover {
            background: $brandColor !important;
          }
        }
        &.ant-btn-primary {
          cursor: pointer;
          border-radius: unset!important;
          color: white;
          font-size: 13px;
          line-height: 18px;
          background-color: $brandColor;
          &:hover {
            background:$hoverColor!important;
          }
          :focus{
            background: unset !important;
          }
        }
      }
    }
  }
  @media all and (max-width: 600px) {
    width: calc(100% - 80px) !important;
    .ant-modal-content {
      flex-direction: column;
      .custom-input-group {
        width: 100% !important;
      }
      .ant-modal-footer {
        button {
          height: 40px;
          font-size: 14px;
        }
      }
    }
  }
  @media all and (max-width: 450px) {
    width: calc(100% - 20px) !important;
    .ant-modal-content {
      .ant-modal-footer {
        button {
          padding: 0 15px;
        }
      }
    }
  }
}
