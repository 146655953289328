@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.HousesFilters-Wrapper{
  display: flex;
  flex-direction: column;
  width: calc(100% - 370px);
  position: relative;
  left: 370px;
  top: 135px;
  .AddressWrapper {
    display: flex;
    justify-content:flex-end;
    align-items: center;
    padding-right: 6%;

    .address-left-wrapper {
      margin-top: 20px;
      margin-right: 50px;

      .address-title {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 700;
        display: inline-block;
        margin-bottom: 5px;
      }
      @media screen and (max-width: 450px){
        margin-right: 30px;
      }
    }

    .address-right-wrapper {
      display: flex;
      align-items: center;
      margin-top: 20px;

    }

    .item-card {
      display: flex;
      align-items: center;

      .icon-wrapper {
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color:$brandColor;
        margin-right: 20px;
        transition: .2s;
        svg{
          width: 26px;
          height: 26px;
        }
      }

      .item-card-content-title {
        color: #BFBCC2;
        font-weight: 500;
        margin-bottom: 2px;
        font-size: 14px;
      }

    }
  }

  .filters {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 9%;

    .filter-top {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .building {
        display: flex;
        margin: 12px 15px 0 0;

        .building-filter-item {
          padding: 2px;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          font-size: 18px;
          font-weight: 700;
          transition: .2s;
          border-radius: 6px;

          &:not(:last-child) {
            margin-right: 8px;
          }

          &:first-child {
            padding: 16px 20px;
            margin-right: 16px;
            font-weight: 500;
            font-size: 16px;
          }

          &:not(:first-child) {
            height: 50px;
            width: 50px;
            cursor: pointer;
          }

          &.active {
            background-color: $brandColor;
          }
        }
      }

      .filters-sort-part {
        display: flex;
        justify-content: flex-end;
        margin: 12px 0 0 0;

        .sort {
          width: 220px;
          margin-left: 16px;

          .ant-select {
            &.ant-select-focused {
              &:focus {
                border: none;
              }
            }

            .ant-select-selector {
              height: 44px;
              display: flex;
              align-items: center;
              border: 1px solid #D2D2D2;



              .ant-select-selection-item {
                color: black;
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.03em;
              }

              &:focus {
                outline: 4px solid $brandColor;
                border: 5px solid $brandColor;
              }

              &:active {
                border-color: $brandColor;
              }
            }

            .ant-select-arrow {
              width: 20px;
            }

          }

          .ant-select-dropdown {
            padding: 8px 0 16px;

            .ant-select-item-option {
              padding: 8px 20px;
            }

            .ant-select-item-option-selected {
              background-color: #f5f5f5;
            }
            @media screen and (min-width: 600px){
              width: 260px!important;
            }
          }


        }
        .status-sort {
          width: 220px;
          margin-left: 0;

          .statuses-wrapper {
            width: 220px;
          }

          .dropdown-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            border: 1px solid #D2D2D2;
            div{
              font-size: 13px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0.03em;
              text-align: left;
              color:#ACACAC;
              .ant-radio-wrapper{
                margin-right:12px;
              }
            }
          }

          .ant-dropdown-trigger {
            color: black;
            background: white;
            display: flex;
            align-items: center;
            padding: 8px 20px;
            .status-sort-icon{
              transform: none;
              transition: transform 0.2s;
              transform-origin: center;
            }
            @media screen and (max-width:500px){
              padding: 8px 10px;
              div{
                font-size: 12px;
              }
            }
          }

          .ant-select {
            .ant-select-selector {
              background-color: white;
            }
          }
        }
      }
    }

    .filter-down {
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      border-radius: 6px;
      margin-top: 10px;

      .floor, .roomsCount {
        display: flex;
        cursor: pointer;
        align-items: center;

        .rooms-count-item {
          border-left: 1px solid #E9E9E9;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          box-sizing: content-box;

          &:hover {
            color: #A18E77;
          }

          &.active {
            border-color: transparent !important;
            background-color: rgba(255, 212, 38, .5) !important;
            border-radius: 4px;

            & + .rooms-count-item {
              border-color: transparent !important;
            }

            &:hover {
              color: #202020 !important;
            }
          }
        }
      }

      .roomsCount {
        margin-right: 12px;
      }

      .floor {
        border-right: 1px solid #E9E9E9;
        transition: .2s;

        &.active {
          color: $brandColor;

          svg {
            polygon {
              transition: .2s;
              fill: $brandColor;
            }
          }
        }
      }

      .field-title {
        display: flex;
        align-items: center;
        font-weight: 500;
        margin: 0 12px;
        color: #202020;
      }

      .inputs {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-grow: 1;

        .fields-wrapper {
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-grow: 1;
          border-left: 1px solid #E9E9E9;
          padding: 12px 0;
        }

        .custom-input-group {
          input {
            margin-bottom: 0;
            border: none;
            border-left: 1px solid #E9E9E9;
            border-right: 1px solid #E9E9E9;
            border-radius: 0;
            padding: 0 8px;
            text-align: center;

            &:focus {
              box-shadow: none !important;
              outline: none;
            }

            &::placeholder {
              text-align: center;
            }
          }
        }
      }

      .arrow-icon {
        margin-left: 6px;
      }
    }

    .clear-filters-wrapper {
      display: flex;
      justify-content: flex-end;
      padding: 24px 0;
      color: #F83636;
      font-weight: 500;
      position: relative;

      span {
        position: absolute;
        right: 0;
        top: 12px;
        cursor: pointer;
      }
    }
  }

  .houses-wrapper {
    width: 100%;
    padding: 40px 2% 10% 9%;
    .not-found{
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 30px;
      color:$brandColor;
    }

    .houses-list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .cards-wrapper{
        width:calc(90% / 4);
        margin-right: 2%;
        margin-top: 25px;
        .house-card{
          box-shadow: 3px 10px 37px 0 #00000014;
        }
      }
    }
  }
}

.filter-floors-list {
  padding: 20px 15px;
  flex-wrap: nowrap;
  width: 278px;
  box-shadow: 0 0 7px 4px #f8f8f8;
  border-radius: 4px;
  background-color: white;

  .list-item {
    width: 50px;
    height: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #F5F5F5;
    margin: 6px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    transition: .2s;

    &.active {
      background-color: $brandColor;
    }

    &.inactive-floor {
      cursor: default;
      background-color: $darkGreyColor;
    }

    @media all and (min-width: 1000px) {
      &:not(.inactive-floor):hover {
        background-color: $brandColor;
      }
    }
  }

  .btnsWrapper {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;

    .PrimaryButton {
      padding: 0 25px;
      width: calc((100% - 12px) / 2);
    }
  }
}

@media all and (max-width: 1200px) {
  .HousesFilters-Wrapper{
    .houses-wrapper{
      width: 100%;
      .houses-list{
        .cards-wrapper{
          width:calc(90% / 3)!important;
        }
      }
    }
  }
}

@media all and (max-width: 1000px) {
  .HousesFilters-Wrapper {
    width: 100%;
    left: 0;
    margin-bottom: 80px;
    .AddressWrapper{
      padding-left: 4%;
    }
    .houses-wrapper{
      width: 100%;
      padding: 40px 40px 10% 40px;
      .houses-list{
        .cards-wrapper{
          width:calc(90% / 4)!important;
        }
      }
    }
  }
}

@media all and (max-width: 800px) {
  .HousesFilters-Wrapper {
    .houses-wrapper{
      width: 100%;
      .houses-list{
        .cards-wrapper{
          width:calc(90% / 3)!important;
        }
      }
    }
  }
}

@media all and (max-width: 600px) {
  .HousesFilters-Wrapper {
    .filters {
      margin-left: unset;
      padding-left: 4%;
      padding-right: 4%;
      .filter-top {
        .filters-sort-part{
          width: 100%;
          justify-content: space-between;
          .status-sort{
            width: 46%;
          }
          .sort{
            width: 46%;
          }
        }
      }
    }
    .houses-wrapper{
      width: 100%;
      .houses-list{
        .cards-wrapper{
          width:calc(90% / 2)!important;
        }
      }
    }
  }
}
@media all and (max-width: 450px) {
  .HousesFilters-Wrapper{
    .houses-wrapper{
      width: 100%;
      .houses-list{
        .cards-wrapper{
          width:100%!important;
        }
      }
    }
  }
}


@media all and (max-width: 486px) {
  .HousesFilters {
    .filters {

      .filter-top {
        .sort {
          width: 100%;
        }
      }

      .filter-down {
        .floor {
          width: 100%;
          margin-right: 0;
        }
      }

      .roomsCount {
        justify-content: unset;

      }
    }
  }
}
.house-filter-checkbox {
  font-weight: 700;
  white-space: normal;

  .ant-radio-inner {
    width: 18px;
    height: 18px;

    &::after {
      width: 10px;
      height: 10px;
    }
  }

  .ant-radio-input:focus + .ant-radio-inner {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #A18E77;;
  }

  .ant-radio-checked {

    .ant-radio-inner {
      border-color: #A18E77;;

      &::after {
        width: 100%;
        height: 100%;
        background-color:#A18E77;;
      }
    }

    &::after {
      border: 1px solid #A18E77;;
    }
  }
}
