@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.AboutWrapper {
  width: calc(100% - 370px);
  position: relative;
  left: 370px;
  top: 135px;

  .about-block{
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    .CountsWrapper {
      padding-top: 33%;
      position: relative;

      .counts-bg {
        position: absolute;
        top: -15px;
        left: 0;
        width: 100%;
        height: 100%;
        padding: $mainPadding;

        .about-main-title {
          font-size: 3.5vw;
         font-weight: 700;
          color: white;
        }

        .counts-main-content {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .counts-item {
            opacity: 0;
            text-align: center;
            text-transform: uppercase;
            color: white;
            animation: fadeIn 2s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;

            &:nth-child(1) {
              animation-delay: 1 * .2s;
            }
            &:nth-child(3) {
              animation-delay: 3 * .2s;
            }
            &:nth-child(5) {
              animation-delay: 5 * .2s;
            }
            &:nth-child(7) {
              animation-delay: 7 * .2s;
            }

            @keyframes fadeIn {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }

            .count {
              color: $brandColor;
              font-size: 4.5vw;
              font-weight: 900;
            }

            .count-title {
              font-size: 17px;
              font-weight: 900;
            }
          }

          .line {
            opacity: 0;
            border-left: 1px solid white;
            height: 100%;
            animation: fadeIn 2s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;

            &:nth-child(2) {
              animation-delay: 2 * .2s;
            }
            &:nth-child(4) {
              animation-delay: 4 * .2s;
            }
            &:nth-child(6) {
              animation-delay: 6 * .2s;
            }
          }
        }
      }
    }
  }

  .AboutMainContent {
    padding: 0 5% 0 5.4%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    margin-bottom: 14%;

    .background-block{
      position: absolute;
      top: 0;
      right: 0;
      width: 52%;
      height: 30%;
      background: #CDBB99;
      opacity: 0.1;
    }
    .LeftSide{
      width: 100%;
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .img_wrapper{
        width: 46%;
        .leftImg-block{
          position: relative;
          padding-top: 100%;
          img{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      .text-wrapper{
        width: 44%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        .aboutUs-block{
          width: 100%;
          padding-top: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .about-description{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            .number{
              font-size: 40px;
              font-weight: 700;
              line-height: 52px;
              letter-spacing: 0.03em;
              text-align: left;
            }
            .texts{
              width: 70%;
              font-size: 16px;
              font-weight: 300;
              line-height: 22px;
              letter-spacing: 0.06em;
              text-align: left;

            }
          }
        }
        .LeftSideTitle{
          font-size: 21px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0.03em;
          text-align: left;
          color:$brandColor;
          margin-bottom: 20px;
        }
      }
    }
    .RightSide{
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      margin: 60px 0;
      .rightImg-wrapper{
        width: 48%;
        .rightImg-block{
          padding-top: 66%;
          position: relative;
          img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
      .text-wrapper{
        width: 46%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        .RightSideTitle{
          font-size: 21px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0.03em;
          text-align: left;
          color:$brandColor;
          margin-bottom: 20px;
        }
        .buildings-text{
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          font-weight: 400!important;

          span{
            margin-top: 10px;
            width: 10px;
            height: 10px;
            background:$brandColor;
          }
          p{
            font-weight: 300;
            width: 92%;
            span{
             font-weight: 300;
            }
          }
        }

      }
    }
  }


  @media all and (max-width: 1600px) {

    .AboutTextContent {
      .TextWrapper {
        font-size: 15px;
        .wrapper-title {
          margin-bottom: 30px;
        }

        .description {
          margin-bottom: 60px;
        }
      }
    }
  }

  @media all and (max-width: 1300px) {

    .AboutTextContent {
      .slider-wrapper {
        width: 48%;
      }
      .TextWrapper {
        width: 48%;
        font-size: 15px;
        &.LeftTextWrapper {
          width: 48%;
        }
        .description {
          line-height: 22px;
          font-size: 15px;
          margin-bottom: 30px;
        }
        .btnsWrapper {
          button {
            font-size: 15px;
            height: 42px;
          }
        }
      }
    }
  }

  @media all and (max-width: 1000px) {
    width: 100%;
    left: 0;
    top: 132px;
    .AddressWrapper{
      padding-left: 4%;
      padding-right:unset;
      margin-bottom: 50px;
    }

    .AboutMainContent {
      padding: 0 20px 0 20px;
      .background-block{
        width: 100%;
        top:98px;
        height: 440px;
      }
      .LeftSide{
        display: flex;
        flex-direction: column;
        .text-wrapper{
          width: 100%;
          margin-bottom: 45px;
        }
        .img_wrapper{
          width: 100%;
        }
      }
      .RightSide{
        .text-wrapper{
          width: 100%;
        }
        .rightImg-wrapper{
          display: none;
        }
      }
    }
    .counts-bg{
      top: 0!important;
    }

    .CountsWrapper {
      height: 300px;
    }
  }

  @media all and (max-width: 450px) {
    .CountsWrapper {
      height: 200px;
    }
    .AboutMainContent{
      .LeftSide{
        .text-wrapper{
          .aboutUs-block{
            .about-description{
              .number{
                font-size: 28px;
              }
              .texts{
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
