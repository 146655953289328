@import "../../assets/styles/mainVariables";

.locations-wrapper {
  width: calc(100% - 370px);
  position: relative;
  left: 370px;
  top: 114px;
  margin-bottom: 5%;
  .map-wrapper {
    padding-left: 6%;
    width: 100%;
    height: 500px;
    position: relative;

    .yandexMap {
      width: 100%;
      height: 100%;

      .ymaps-2-1-79-map {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 1000px){
    width: 100%;
    left: 0;
    top: 48px;
    margin-bottom: 6%;
    .map-wrapper{
      padding-left: 3%;
      width: 100%;
    }
  }
}