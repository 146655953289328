@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.PrivacyWrapper {
  width: calc(100% - 376px);
  position: relative;
  left: 376px;
  padding: 15% 3% 20px 3%;
  font-weight: 400;
  text-align: center;

  .privacy-policy-text *{
    font-family: inherit !important;
  }
  @media screen and (max-width: 1000px){
    width: 100%;
    left: 0;
    padding: 8% 3% 20px 3%;
    background-color: white;
    margin-top: 40px;
    height: 100vh;
  }
}
