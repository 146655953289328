@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.FloorWrapper {
  .ContentWrapper {
    width: calc(100% - 370px);
    position: relative;
    left: 370px;
    top: 135px;
    .DistributeWrapper{
      width: 100%;
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: #EEEEEE;
      opacity: 0.6;
      padding-right: 3%;
      .share-block{
        height: 100%;
        display:flex;
        align-items: center;
        margin-right: 40px;
        span{
          font-size: 15px;
          font-weight: 300;
          line-height: 18px;
          letter-spacing: 0.03em;
          text-align: left;
          color: #484848;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        svg{
          width: 26px;
          height: 26px;
          margin-right: 12px;
        }
        .share{
          height: 32px;
          display: flex;
          align-items: center;
        }
      }
    }
    .FloorMainContent {
      display: flex;
      justify-content: space-between;
      padding: 0 7% $mainPadding 6%;
      position: relative;
      height: 600px;

      .LeftSide {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: -44px;
        background-color: white;
        width: 286px;

        .floors-main-wrapper {
          display: flex;
          flex-direction: column;

          .floors-content {
            position: relative;
            z-index: 2;
            flex-wrap: nowrap;
            width:286px;
            background: #FFFFFF;
            box-shadow: 3px 10px 37px rgba(0, 0, 0, 0.08);

            .selected-floor {
              background-color: $brandColor;
              height: 52px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0.03em;
              text-align: left;
              color: #FEFEFE;

            }

            .floors-list {
              margin:32px;
              text-align: center;
              border: 1px solid #D2D2D2;
              .list-item {
                width: calc(100% / 4);
                height: 48px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                cursor: pointer;
                transition: .2s;
                color: #000000;
                border-left: 1px solid #D2D2D2;
                border-top: 1px solid #D2D2D2;
                &:first-child{
                  border-left: unset;
                  border-top: unset;
                  cursor: no-drop;
                  &:hover{
                    background-color:transparent!important;
                    color: black!important;
                  }
                }
                &:nth-child(2){
                  border-top: unset;
                }
                &:nth-child(3){
                  border-top: unset;
                }
                &:nth-child(4){
                  border-top: unset;
                }
                &:nth-child(5){
                  border-left: unset;
                }
                &:nth-child(9){
                  border-left: unset;
                }
                &:nth-child(13){
                  border-left: unset;
                }

                &.active {
                  background-color: $brandColor;
                  color: white;
                }

                &.inactive-floor {
                  cursor: default;
                  background-color: $darkGreyColor;
                }

                &:not(.inactive-floor):hover {
                  background-color:$hoverColor;
                  color: white;
                }
              }
            }
          }

          .parking-wrapper {
            flex-wrap: nowrap;
            margin:32px;
            display: flex;
            justify-content:center;
            flex-direction: column;
            width: 80%;
            .parking-title{
              display: flex;
              justify-content: flex-start;
              font-size: 13px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0.03em;
              text-align: left;
              color:$textColor;
              margin-bottom: 10px;
            }

            .parking-floors-list {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #D2D2D2;

              .list-item {
                width: calc(100% / 2);
                height: 48px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                cursor: pointer;
                transition: .2s;
                &:first-child{
                  border-right: 1px solid #D2D2D2;
                }

                &.active {
                  background-color: $brandColor;
                }

                &.inactive-floor {
                  cursor: default;
                  background-color: $darkGreyColor;
                }

                &:not(.inactive-floor):hover {
                  background-color:$hoverColor;
                  color: white;
                }
              }
            }
          }
          .freedom-buildings{
            margin: 32px;
            display: flex;
            justify-content: flex-start;
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.03em;
            text-align: left;
            color: black;

          }

          .compass-wrapper {
            position: relative;
            z-index: 1;
            margin-left: 40px;
            width: 150px;
            display: flex;
            align-items: flex-end;
            animation: rotate 1.6s ease-in-out;
            animation-iteration-count: 1;

            img {
              width: 100%;
            }

            @keyframes rotate {
              0% {
                transform: rotate(0deg);
              }
              25% {
                transform: rotate(30deg);
              }
              50% {
                transform: rotate(-5deg);
              }
              75% {
                transform: rotate(15deg);
              }
              100% {
                transform: rotate(0deg);
              }
            }
          }
        }
        .mobile-badges-wrapper{
          .compos-wrapper{
            display: none;
          }
        }
      }

      .badges-wrapper {
        padding: 42px 0;
        display: flex;
        justify-content: space-between;

        .badge-item {
          display: flex;
          align-items: center;

          .badge-color {
            width: 10px;
            height: 10px;
            margin-right: 6px;
          }
          .color-text{
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0.02em;
            text-align: left;
            color: #282825;
          }
        }

        .compass-wrapper-in-right {
          display: none;
        }
      }
      .RightSide {
        width: 60%;
        overflow: hidden;
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        right: 0;
        top: 80px;

        @media screen and (min-width: 1600px){
          width: 54%;
        }
        @media screen and (min-width: 1800px){
          width: 58%;
        }
      }

      .ImageWrapper {
        width: 100%;
        margin-right: 6%;
        position: relative;
        display: flex;
        align-items: baseline;
        padding-top: 47.8%;


        .sold-item {
          position: absolute;
          width: 50%;
          height: unset;
          top: 50%;
          left: 50%;
          z-index: 103;
          transform: rotate(20deg) translate(-50%, 50%);
        }

        &.ParkingImageWrapper {
          padding-top: 53.1%;

          img, svg {
            width: 100%;
          }
          .sold-item {
            width: 40%;
            transform: rotate(20deg) translate(-15%, 50%);
          }
        }

        img, svg {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 100;
        }

        img {
          height: unset;
        }

        svg {
          z-index: 101;

          &:hover polygon, &:hover path, &:hover rect {
            opacity: 1;
            fill: transparent;
            stroke: transparent;
          }

          polygon, path, rect {
            cursor: pointer;
            fill: transparent;
            stroke: transparent;
            transition: .3s;
            animation-iteration-count: 1;
          }
        }

        .tooltip-wrapper {
          display: flex;


          .tooltip-left-part {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            padding: 10px;
            height: 66px;
            justify-content: center;
            color: white;

            &.without-right-part {
              border-radius: 4px;
              padding: 4px 12px;
              height: 40px;
            }

            .building-number {
              font-size: 15px;
              font-weight: 700;
            }

            .building-text {
              font-size: 16px;
              font-weight: 700;
            }
          }

          .tooltip-right-part {
            display: flex;
            flex-direction: column;
            background: white;
            padding: 10px 20px;
            height: 66px;
            justify-content: center;
            box-shadow: unset;

            .floors-count {
              font-size: 14px;
              color: #868686;
            }

            .houses-count {
              font-size: 16px;
              color: #202020;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1400px){
    .ContentWrapper {
      .FloorMainContent {
        height: 700px;
        .RightSide{
          width: 66%;
        }
      }
    }
  }
  @media screen and (min-width: 1700px){
    .ContentWrapper {
      .FloorMainContent {
        height: 800px;
        .RightSide{
          width: 70%;
        }
      }
    }
  }
  @media screen and (min-width: 2000px){
    .ContentWrapper {
      .FloorMainContent {
        height: 900px;
        .RightSide{
          width: 70%;
        }
      }
    }
  }
  @media screen and (min-width: 2200px){
    .ContentWrapper {
      .FloorMainContent {
        height: 930px;
        .RightSide{
          width: 70%;
        }
      }
    }
  }
  @media all and (max-width: 1400px) {
    .ContentWrapper {
      .FloorMainContent {
        .RightSide{
          width: 60%;
          .ImageWrapper{
            width: 100%;
          }
          .compos-wrapper{
            svg{
              width: 130%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1200px) {
    .ContentWrapper {
      .FloorMainContent {
        .LeftSide{
          width: 32%;
          .color-text{
            font-size: 8px!important;
          }
          .floors-main-wrapper{
            .floors-content{
              width: 100%;
            }
          }
        }
        .RightSide{
          height: 40%;
        }
      }
    }
  }


  @media all and (max-width: 1000px) {
    .ContentWrapper {
      width: 100%;
      left: 0;
      .AddressWrapper{
        padding-left: 4%;
      }
      .DistributeWrapper{
        height: 44px;
        .share-block{
        }
      }
      .FloorMainContent {
        flex-direction: column;
        height: unset;

        .LeftSide {
          width: 100%;
          position: unset;
          flex-direction: column;
          margin:30px 0;
          .badges_block{
            display: none;
          }
          .color-text{
            font-size: 12px!important;
          }
          .floors-main-wrapper{
            .floors-content{
              .floors-list{
                .list-item{
                  width: calc(100% / 5);
                  &:nth-child(4){
                    border-right: 1px solid #D2D2D2;
                  }
                  &:nth-child(5){
                    border-top:unset;
                  }
                  &:nth-child(6){
                    border-left:unset;
                  }
                  &:nth-child(9){
                    border-left: 1px solid #D2D2D2;
                  }
                  &:nth-child(11){
                    border-left:unset;
                    border-bottom: 1px solid #D2D2D2;
                  }
                  &:nth-child(12){
                    border-bottom: 1px solid #D2D2D2;
                  }
                  &:nth-child(14){
                    border-bottom: 1px solid #D2D2D2;
                  }
                  &:nth-child(13){
                    border-top: 1px solid #D2D2D2;
                    border-bottom: 1px solid #D2D2D2;
                    border-left: 1px solid #D2D2D2;
                  }
                  &:nth-child(15){
                    border-bottom: 1px solid #D2D2D2;
                  }
                  &:nth-child(16){
                    border-bottom:unset;
                    border-top: unset;
                    border-right: 1px solid #D2D2D2;
                    margin-right: 1px;
                  }
                }
                //:hover {
                //  background-color:unset!important;
                //  color:unset!important;
                //}
              }
              .parking-wrapper{
                width: unset!important;
                .parking-floors-list{
                  width: 100%;
                }
              }
            }
          }

          .mobile-badges-wrapper{
            margin: 40px 0 0 30px;
            width: 100%;
            display: flex;
            align-items: center;
            .compos-wrapper{
              display: flex;
              margin-left: 16%;
              margin-bottom: 20px;
            }
            .badges-wrapper {
              flex-direction: column;
              justify-content: flex-start;

              .badge-item {
                margin-bottom: 20px;
              }
            }
          }
        }

        .RightSide {
          position: unset;
          height: 100%;
          width: 100%;
          .compos-wrapper{
            display: none;
          }
          .ImageWrapper{
            width: 100%;
          }
        }
        .mobile-badges-wrapper{
          margin: 0 0 60px 40px;
          .badges-wrapper{
            flex-direction: column;
          }
        }
      }
    }
  }
}
