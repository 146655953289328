@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.HouseWrapper {
  width: calc(100% - 370px);
  position: relative;
  left: 370px;
  top: 135px;
  margin-bottom: 8%;
  .pdfBlock{
    opacity: 0;
    @media screen and (max-width: 1000px){
      .pdf-img{
        display: contents;
      }
    }
  }
  .similar-wrapper{
    margin-left: 5%;
    .ProfitableOffer{
      .offer-slider-block{
        .slick-slider{
          .slick-list{
            .slick-track{
              margin: 0;
            }
          }
        }
      }
    }
    @media screen and (max-width: 1000px){
      margin-left:2%;
    }
  }
  .ContentWrapper {
    .AddressWrapper {
      display: flex;
      justify-content:flex-end;
      align-items: center;;

      .address-left-wrapper {
        margin-top: 20px;
        margin-right: 50px;

        .address-title {
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 700;
          display: inline-block;
          margin-bottom: 5px;
        }
      }

      .address-right-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 20px;
        .parking-icon-wrapper{
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color:$brandColor;
          margin-right: 20px;
          transition: .2s;
          svg{
            width: 26px;
            height: 26px;
          }
          &:hover{
            background-color:$hoverColor;
          }
        }

      }

      .item-card {
        display: flex;
        align-items: center;

        .icon-wrapper {
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color:$brandColor;
          transition: .2s;
          svg{
            width: 26px;
            height: 26px;
          }
          &:hover{
            background-color:$hoverColor;
          }
        }

        .item-card-content-title {
          color: #BFBCC2;
          font-weight: 500;
          margin-bottom: 2px;
          font-size: 14px;
        }

        .item-card-content-text {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
         font-weight: 500;
          font-size: 16px;
        }
      }
    }
    .DistributeWrapper{
      width: 100%;
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: #EEEEEE;
      opacity: 0.6;
      padding-right: 7%;
      .share-block{
        width:100%;
        height: 100%;
        display:flex;
        align-items: center;
        justify-content: end;
        span{
          font-size: 13px;
          font-weight: 300;
          line-height: 18px;
          letter-spacing: 0.03em;
          text-align: left;
          color: #484848;
          display: flex;
          align-items: center;
        }
        svg{
          width: 26px;
          height: 26px;
          margin-right: 12px;
        }
        .share{
          height: 32px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .line{
          margin: 0 26px;
          width: 1px;
          background: #D2D2D2 ;
          height: 32px;
        }
        .print{
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
    }


    .FloorMainContent {
      display: flex;
      align-items: flex-start;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: $mainPadding 7% 0 6%;

      .house-details-checkbox {
        font-weight: 700;
        white-space: normal;

        .ant-radio-inner {
          width: 18px;
          height: 18px;

          &::after {
            width: 10px;
            height: 10px;
          }
        }

        .ant-radio-input:focus + .ant-radio-inner {
          -webkit-box-shadow: none;
          box-shadow: none;
          border-color: $brandColor;
        }

        .ant-switch-checked {

          .ant-switch-inner {
            border-color: $brandColor;

            &::after {
              background-color: $brandColor;
            }
          }

          &::after {
            border: 1px solid $brandColor;
          }
        }
      }

      .LeftSide {
        width: calc(64% - 80px);

        .checkboxes-wrapper {
          display: none;

          .ant-radio-group {
            margin: 12px 0 20px;

            .house-details-checkbox {
              margin-top: 8px;
            }
          }
        }

        .left-side-slider {
          display: flex;
          align-items: flex-end;
          flex-direction: row-reverse;

          .slider-wrapper {
            width: calc(100% - 180px);
            background-color: white;
            padding: 0 4px;
            border-radius: 0 0 8px 8px;
            position: relative;
            z-index: 2;


            .images-list-wrapper {
              width: calc(100% + 5px);
              margin-top: 12px;
              position: relative;
              cursor: pointer;

              .slick-track {

                margin-left: unset;
              }

              .slick-slide {
                padding-right: 12px;
                outline: none;

                .list-item {
                  width: 100%;
                  padding-top: 100%;
                  position: relative;
                  cursor: pointer;
                  border-radius: 8px;

                  &:focus {
                    outline: none;
                  }

                  .list-img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-radius: 5px;
                    background-color: white;

                    &:focus {
                      outline: none;
                    }
                  }

                  &.active::after {
                    content: '';
                    position: absolute;
                    bottom: -4px;
                    width: 100%;
                    height: 2px;
                    background: $brandColor;
                  }

                }
              }

              &:hover .slider-btn {
                visibility: visible;
              }

              .slider-btn {
                visibility: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 40px;
                transition: .2s;
                background-color: white;
                box-shadow: 0 1px 4px 0 rgba(10, 12, 11, .2);
                &:hover{
                  background-color:$brandColor;
                }

                img, svg {
                  width: 12px;
                  height: 12px;

                  path {
                    stroke: black;
                  }
                }

                &:hover svg {
                  path {
                    stroke:white;
                  }
                }

                &:disabled {
                  visibility: hidden;
                }

                &.prev-btn {
                  left: -32px;
                  position: absolute;
                  top:50%;
                  transform: translateY(-50%);
                }

                &.next-btn {
                  right: -8px;
                  position: absolute;
                  top:50%;
                  transform: translateY(-50%);
                }
              }
            }

            .main-img-wrapper {
              width: 100%;
              position: relative;
              overflow: hidden;
              border-radius: 8px;
              background-color: white;

              .main-img-content {
                position: relative;
                width: 100%;
                padding-top: 71.4%;

                .main-img {
                  border-radius: 8px;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 0;
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                  object-position: center;
                }
              }

              .product-current-img {
                width: 100% !important;
                height: 100% !important;
              }

              .mobile-slider-img {
                position: absolute !important;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
                object-fit: cover;
                display: none;
              }
            }

            &.other-projects-images-list {
              width: calc(8 * 50px + 126px);
            }
          }


          .image-loader {
            width: 90%;
            height: 45vh;
            background: #f5f5f5;
          }
        }

        .identical-houses-list {
          padding: 15px;
          box-shadow: 0 0 7px 4px #f8f8f8;
          max-width: calc(8 * 50px + 126px);
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(57px, 1fr));

          .list-item {
            width: 50px;
            height: 50px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #F5F5F5;
            margin: 6px;
            border-radius: 4px;
            font-size: 18px;
            font-weight: 700;
            cursor: default;
            transition: .2s;

            &.active {
              background-color: $brandColor;
            }

            &.inactive-floor {
              cursor: default;
              background-color: $darkGreyColor;
            }
          }
        }

        .badges-wrapper {
          padding: 30px 0;
          display: flex;
          flex-wrap: wrap;
          max-width: calc(8 * 50px + 126px);
          justify-content: space-between;

          .badge-item {
            display: flex;
            margin-right: 10px;

            .badge-color {
              width: 20px;
              height: 20px;
              border-radius: 4px;
              margin-right: 10px;
            }
          }

          .compass-wrapper-in-right {
            display: none;
          }
        }

        .compass-wrapper {
          position: relative;
          z-index: 1;
          width: 94px;
          height: 94px;
          display: flex;
          align-items: flex-end;
          margin-right: 40px;
          //animation: rotate 1.6s ease-in-out;
          //animation-iteration-count: 1;

          img {
            width: 100%;
          }

          @keyframes rotate {
            0% {
              transform: rotate(0deg);
            }
            25% {
              transform: rotate(30deg);
            }
            50% {
              transform: rotate(-15deg);
            }
            75% {
              transform: rotate(15deg);
            }
            100% {
              transform: rotate(0deg);
            }
          }
        }
      }

      .RightSide {
        width: 36%;
        position: relative;
        top: -100px;
        background: #FFFFFF;
        box-shadow: 3px 10px 37px rgba(0, 0, 0, 0.08);

        .upload-pdf-wrapper {
          display: flex;
          justify-content: space-between;
          font-size: 15px;
          border-bottom: 1px solid #D2D2D2;
          margin:24px 0 24px 31px;
          .house-badge{
            padding-bottom: 16px;
          }

          .upload-btn {
            display: flex;
            align-items: center;
            background-color: inherit;
            color: #000;
            font-weight: 500;
            transition: .2s;
            cursor: default;

            &.active:hover {
              cursor: pointer;
              color: #BFBCC2;
            }

            &.active:hover svg {
              path {
                fill: #BFBCC2;
              }
            }

            svg {
              width: 20px;
              margin-left: 10px;

              path {
                transition: .2s;
              }
            }
          }
        }

        .header-title {
          font-size: 28px;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: 0.03em;
          text-align: left;

          padding: 32px 32px 20px 32px;
          border-bottom: 1px solid #D2D2D2;
        }

        .areas-list {
          margin:0 0 20px 31px;
          padding: 0 32px 32px 0;
          border-bottom: 1px solid #D2D2D2;

          .list-item {
            display: flex;
            align-items: center;
            margin-top: 12px;
            font-size: 15px;

            .item-name {
              margin-right: 8px;
              font-size: 16px;
              font-weight: 300;
              line-height: 22px;
              letter-spacing: 0.04em;
              text-align: left;
              color: black;
            }

            .line {
              flex-grow: 1;
              border-top: 1px dotted #0000002E;
            }

            .item-area {
              margin-left: 8px;
              font-size: 16px;
              font-weight: 300;
              line-height: 22px;
              letter-spacing: 0.06em;
              text-align: right;
              color: black;
            }
          }

          .house-details-checkbox {
            font-size: 18px;
            margin-bottom: 20px;
            margin-top: 20px;

            .ant-radio-inner {
              top: -2px;
            }
          }
        }

        .price-wrapper {
          background-color:$brandColor;
          display: flex;
          justify-content: space-between;

          .price {
            display: flex;
            align-items: center;
            font-size: 22px;
            font-weight: 500;

            svg {
              width: 20px;
              height: 20px;
              margin-top: -3px;
            }
          }

          .PrimaryButton {
            width: 100%;
            display: flex;
            align-items: center;
            color: white;
            &:disabled {
              background-color:$brandColor !important;
            }
            &:hover{
              background-color:$hoverColor;
            }
          }
        }

        .linkWrapper {
          line-height: 26px;
          letter-spacing: 0.4px;
          margin-top: 24px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;
          flex-wrap: wrap;
          font-size: 13px;
          padding:0 32px;
          .switch-wrapper{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            span{
              margin-right: 12px;
              font-size: 13px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0.03em;
              text-align: left;

            }
          }
          .price-block{
            font-size: 26px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: 0.03em;
            text-align: left;
            margin-bottom: 14px;

          }

          .link-text {
            color: #007AFE;
            text-decoration: underline;
            cursor: pointer;
            margin-right: 20px;
          }

          .monthly-price-wrapper {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.03em;
            text-align: left;
            color:$brandColor;
            margin-bottom: 42px;
            a{
              display: flex;
            }


            .monthly-price {
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: 700;
              margin-left: 8px;
            }

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
  .like-houses-block{
    padding-left: 7%;
  }
  @media all and (min-width: 1600px) {
    .ContentWrapper {
      .FloorMainContent {
        .LeftSide {
          width: calc(64% - 100px);
        }

        .RightSide {
          width: 30%;
        }
      }
    }
  }

  @media all and (max-width: 1400px){
    .ContentWrapper {
      .FloorMainContent {
        .LeftSide {
          width: calc(60% - 20px);
        }

        .RightSide {
          width: 40%;
          .header-title{
            font-size: 26px;
          }
          .linkWrapper{
            .price-block{
              font-size: 22px;
            }
            .monthly-price-wrapper{
              font-size: 11px;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 1200px){
    .ContentWrapper {
      .FloorMainContent {

        .RightSide {
          width: 44%;
          .header-title{
            font-size: 22px;
          }

          .price-wrapper {
            padding: 16px;

            .price {
              font-size: 22px;
            }

            .PrimaryButton {
              font-size: 15px;
              height: 40px;
              padding: 0 20px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1000px) {
    width: 100%;
    left: 0;
    .like-houses-block{
      margin-bottom: 30%!important;
      margin-top: 40px;
    }
    .ContentWrapper {
      .DistributeWrapper{
        height: 44px;
        .share-block{
          width: 40%;
        }
      }
      .AddressWrapper{
        padding-left: 4%;
        .address-right-wrapper{
          padding-right: unset;
        }
      }

      .FloorMainContent {
        flex-direction: column;

        .LeftSide {
          flex-direction: row;
          width: 100%;
          margin-bottom: 40px;

          .left-side-slider{
            flex-direction: column;
            align-items: unset;
            .slider-wrapper {
              width: 100% !important;
            }
            .compass-wrapper{
              left: 0;
              align-items: flex-start;
              margin-top: 30px;
            }
          }

          .image-loader {
            width: 100%;
            height: 45vh;
          }
        }

        .RightSide {
          flex-direction: column;
          position: unset;
          width: 100%;
          .mobile-badges-wrapper{
            .badges-wrapper{
              display: flex;
              flex-direction: column!important;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 650px){
    .ContentWrapper{
      .DistributeWrapper{
        .share-block{
          width: 60%;
        }
      }
    }
  }

  @media screen and (max-width: 550px){
    .ContentWrapper{
      .DistributeWrapper{
        .share-block{
          width: 80%;
        }
      }
    }
  }
  @media screen and (max-width: 450px){
    .ContentWrapper{
      .AddressWrapper{
        .address-left-wrapper{
          margin-right: 20px!important;
        }
      }
    }
  }

  @media screen and (max-width: 350px){
    .ContentWrapper{
      .DistributeWrapper{
        .share-block{
          width: 100%;
        }
      }
    }
  }

}

.pdfs-wrapper {
  min-width: 150px !important;

  .ant-dropdown-menu {
    .ant-dropdown-menu-item:not(:last-child) {
      border-bottom: 1px solid $greyBorderColor;
    }
  }

  .upload-btn {
    padding-right: 0 !important;
  }
}