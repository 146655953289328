@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.BuildingsWrapper {
  margin: 135px 0 0 0;
  width: calc(100% - 370px);
  position: relative;
  left: 370px;

  .buildings-text{
    p{
      font-weight: 300!important;
      span{
        font-weight: 300!important;
      }
    }
  }

  .info-block{
    width: 78%;
    height: 160px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    bottom: -100px;
    .display-none{
      display: none!important;
    }
    h3{
      color: white;
      font-size: 40px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: left;
    }
    sup{
      font-size: 72%!important;
      top: -0.3em!important;
      margin-left: 2px;
    }
    span{
      color: white;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.06em;
      text-align: left;
      width: 60%;
      padding-left: 4px;
    }
    .about-info{
      width: 80%;
      height: 100%;
      padding: 10px 10px 10px 10%;
      background-color: rgba(203, 186, 155, 0.9);
      display: flex;
      align-items: center;
      justify-content:space-around;
      :nth-child(3){
        margin-right: 25px;
      }
      :nth-child(2){
        margin-right: 30px;
      }
      p{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: white;
        width:calc(96% / 4);
        .some-text{
          width: 50%;
        }
      }
    }
    .builds-info{
      width: 20%;
      height: 100%;
      background-color: #CDBB99;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 20px;
      .builds{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        svg{
          width: 36px;
          height: 36px;
          position: unset;
          margin-left: 6px;
          path{
            fill: white !important;
          }
          &:hover{
            path{
              fill: white;
            }
          }
        }
      }
      &:hover{
        background-color:$hoverColor!important;
      }
    }
  }
  .TopContent {
  padding-right:7%;
    .AddressWrapper {
      padding-right: unset!important;
    }

    .ImageWrapper {
      position: relative;
      width: 100%;
      display: flex;
      align-items: baseline;
      border-radius: 6px 0 0 6px;
      padding-top: 100%;

      img, svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;
      }
      .svg-wrapper{
        svg {
          z-index: 101;
          transition: .3s;

          &:hover polygon, &:hover path {
            animation: none;
            opacity: 1;
            fill: transparent;
            stroke: transparent;
          }


          polygon, path {
            cursor: pointer;
            fill: transparent;
            stroke: transparent;
            transition: .3s;
            animation: showElem 3s ease-out infinite;
            animation-delay: .5s;

            @keyframes showElem {
              0% {
                stroke: transparent;
                fill: transparent;
              }
              50% {
                stroke: $brandColor;
                fill: $brandColor;
              }
              100% {
                stroke: transparent;
                fill: transparent;
              }
            }

            &:hover {
              animation: none!important;
              opacity: 1!important;
              fill: $brandColor;
              stroke: $brandColor;
            }

            &.inactive:hover {
              fill: $greyColor;
              stroke: $greyColor;
              cursor: default;
            }
            @for $i from 1 through 17 {
              &#B#{$i} {
                animation-delay: .15s * $i;
              }
            }
            @for $i from 1 through 16 {
              &#A#{$i} {
                animation-delay: .15s * $i;
              }
            }
          }
        }
      }

      .tooltip-wrapper {
        display: flex;
        align-items: center;

        .tooltip-left-part {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 16px;
          white-space: break-spaces;
         font-weight: 500;
          height: 66px;
          width: 66px;
          justify-content: center;
          background: #CDBB99;
          box-shadow: 3px 10px 37px rgba(0, 0, 0, 0.08);

          &.inactive-floor {
            background-color: $greyColor;
          }

          .building-number {
            font-size: 19px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0.03em;
            text-align: left;
            color:#FEFEFE;

          }

          .building-text {
            font-size: 13px;
            font-weight: 300;
            line-height: 18px;
            letter-spacing: 0.03em;
            text-align: left;
            color: #FEFEFE;

          }
        }

        .tooltip-right-part {
          display: flex;
          flex-direction: column;
          background: white;
          padding: 10px 40px 10px 20px;
          height: 66px;
          white-space: nowrap;
          justify-content: center;

          .floors-count {
            font-size: 14px;
            color: #868686;
          }

          .houses-count {;
            color:$brandColor;
            font-size: 13px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.03em;
            text-align: left;

          }
        }
      }

      .help-swipe {
        visibility: hidden;
        opacity: 0;
      }
    }
    .info-block{
      width: 78%;
      height: 160px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 999;
      bottom: -100px;
      h3{
        color: white;
        font-size: 40px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: left;
      }
      span{
        color: white;
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
        letter-spacing: 0.06em;
        text-align: left;
        width: 60%;
      }
      .about-info{
        width: 80%;
        height: 100%;
        padding: 10px 10px 10px 10%;
        background-color: rgba(203, 186, 155, 0.9);
        display: flex;
        align-items: center;
        justify-content:space-around;
        :nth-child(3){
          margin-right: 25px;
        }
        :nth-child(2){
          margin-right: 30px;
        }
        p{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          color: white;
          width:calc(96% / 4);
          .some-text{
            width: 50%;
          }
        }
      }
      .builds-info{
        width: 20%;
        height: 100%;
        background-color: #CDBB99;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 20px;
        svg{
          width: 36px;
          height: 36px;
          position: unset;
          path{
            fill: white !important;
          }
          &:hover{
            path{
              fill: white;
            }
          }
        }
      }
    }
  }

  .MobileInfoBlock{
    display: none;
  }

  .DescriptionContent {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 160px;
    padding: 0 5% 0 6%;

    .background-block{
      width: 80%;
      height: 60%;
      position: absolute;
      top: 6%;
      background: #D2D2D2;
      opacity: 10%;
    }

    .LeftSide {
      display: flex;
      z-index: 999;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: calc(#{$mainPadding} / 2) 0 calc(#{$mainPadding} / 2) $mainPadding;
      height: 100%;
      .text_wrapper{
        width: 42%;
        .LeftSideTitle {
          font-size: 33px;
          font-weight: 500;
          line-height: 62px;
          letter-spacing: 0.03em;
          text-align: left;
          color:$brandColor;
        }
      }

      .img_wrapper{
        width: 50%;
        .leftImg-block{
          padding-top: 83.5%;
          position: relative;
          img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }

    }

    .buildings-text {
      margin-top: 20px;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.03em;
      text-align: left;
    }

    .RightSide {
      height: 100%;
      display: flex;
      z-index: 999;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      border-radius: 6px 0 0 6px;
      width: 100%;
      padding-top: 40px;

      .text-wrapper{
        width: 46%;
        .RightSideTitle {
          font-size: 28px;
          font-weight: 500;
          line-height: 36px;
          letter-spacing: 0.03em;
          text-align: left;
          color:$brandColor;

        }
      }
      .rightImg-wrapper{
        width: 50%;
        .rightImg-block{
          padding-top: 66%;
          position: relative;
          img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  @media screen and (max-width:1600px){
  .TopContent{
    .ImageWrapper{
      .info-block{
        width: 82% !important;
        .about-info{
          padding: 0 10px 0 6%;
          p{
            h3{
              font-size: 30px;
            }
            span{
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  }
  @media screen and (max-width:1300px){
    .TopContent{
      .ImageWrapper{
        .info-block{
          width: 82% !important;
          .about-info{
            padding: 0 10px 0 6%;
            p{
              h3{
                font-size: 20px;
              }
              span{
                font-size: 12px;
              }
            }
          }
          .builds-info{
            h3{
              font-size: 20px;
            }
            span{
              font-size: 12px;
            }
          }
        }
      }
    }
    .DescriptionContent{
      .LeftSide{
        .text_wrapper{
          .LeftSideTitle{
            font-size: 24px;
            line-height: 42px;
          }
          .buildings-text{
            font-size: 14px;
          }
        }
      }
      .RightSide{
        .text-wrapper{
          .RightSideTitle{
            font-size: 24px;
            line-height: 42px;
          }
          .buildings-text{
            font-size: 14px;
          }
        }
      }
    }
  }

  @media all and (max-width: 1000px) {
    width: 100%;
    left: 0;
    .AddressWrapper{
      padding: 0 4%;
      width: 94%;
    }
    .TopContent {
      padding: unset;
      .ImageWrapper {
        padding-top: 0;
        overflow-x: auto;
        height: calc(100vh - 173px);
        .info-block{
          display:none;
        }

        img {
          width: auto;

        }
        svg {
          width: auto;
        }
        .tooltip-wrapper {
          display: none;
        }
        @keyframes hideSwipeWrapper {
          0% {
            display: none;
            opacity: 0;
            background: rgba(35,37,40,.6);
          }
          50% {
            background: rgba(35,37,40,.6);
            display: flex;
            opacity: 1;
          }
          100% {
            background: transparent;
            display: none;
            opacity: 0;
          }
        }
        .help-swipe {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          background: rgba(35,37,40,.6);
          display: flex;
          height: 100%;
          left: 0;
          position: absolute;
          text-align: center;
          top: 0;
          width: 100%;
          z-index: 105;
          pointer-events: none;
          animation-name: hideSwipeWrapper;
          animation-iteration-count: 1;
          animation-duration:  3s;
          animation-fill-mode: both;


          img {
            animation: helpSwipe .8s linear infinite alternate;
            display: inline-block;
            transform-origin: center center;
            opacity: .7;
          }

          @keyframes helpSwipe {
            0% {
              position: relative;
              left: -20px;
            }
            100% {
              position: relative;
              right: 20px;
            }
          }

        }
      }
    }
    .MobileInfoBlock{
      display: flex;
      width: 100%;
      .info-block{
        position: unset;
        width: 100%;
        height: 100px;
        .about-info{
          p{
            h3{
              font-size: 28px;
            }
            span{
              line-height: unset;
              font-size: 14px;
            }
          }
        }
        .builds-info{
          h3{
            font-size: 28px;
          }
          span{
            line-height: unset;
            font-size: 14px;
          }
          svg{
           margin-left: 5px!important;
          }
        }
      }
    }
    .DescriptionContent {
      flex-direction: column;
      margin-top: 10px;
      padding: unset;
      .background-block{
        width: 100%;
       height: 380px;
      }

      .LeftSide {
        position: unset;
        width: 100%;
        padding: 6% 4%;
        flex-direction: column;
        .text_wrapper{
          width: 100%;
          .LeftSideTitle{
            font-size:28px ;
          }
          .buildings-text{
            font-size: 16px;
          }
        }
        .img_wrapper{
          width: 100%;
          margin-top: 30px;
        }
      }

      .RightSide {
        width: 100%;
        padding:0 4% 6% 4%;
        flex-direction: column;
        .text-wrapper{
          width: 100%;
          .RightSideTitle{
            font-size: 28px;
            line-height: 42px;
          }
          .buildings-text{
            font-size: 16px;
          }
        }
        .rightImg-wrapper{
          width: 100%;
          margin-top: 30px;
        }
      }
    }
  }

  @media screen and (max-width: 700px){
    .MobileInfoBlock{
      .info-block{
        .about-info{
          width: 100%;
          padding: 10px 2px 10px 8px;
          p{
            &:nth-child(3){
              margin-right: 15px!important;
            }
            &:nth-child(2){
              margin-right: 15px!important;
            }
            h3{
              font-size: 22px;
            }
            span{
              font-size: 12px;
            }
          }
        }
        .builds-info{
          display: none;
          h3{
            font-size: 22px;
          }
          span{
            font-size: 12px;
          }
        }
      }
    }
  }

  @media all and (max-width: 500px) {
    .MobileInfoBlock{
      .info-block{
        .about-info{
          p{
            &:nth-child(3){
              margin-right: -12px!important;
            }
            h3{
              font-size: 22px;
            }
            span{
              font-size: 12px;
            }
          }
        }
        .builds-info{
          h3{
            font-size: 22px;
          }
          span{
            font-size: 12px;
          }
        }
      }
    }
    .DescriptionContent {
      flex-direction: column;

      .LeftSide {
        position: unset;
        width: 100%;
        padding: calc(#{$mainPadding} / 2) $mainPadding;

        .LeftSideTitle {
          font-size: 26px;
          line-height: 36px;
        }
      }

      .buildings-text {
        font-size: 15px;
        width: 100%;
      }

      .RightSide {
        width: 100%;

        .RightSideTitle {
          font-size: 24px;
          margin: 16px 0;
        }
      }
    }
  }

  @media screen and (max-width:400px){
    .MobileInfoBlock{
      .info-block{
        .about-info{
          p{
            h3{
              font-size: 18px;
            }
            span{
              font-size: 10px;
            }
          }
        }
        .builds-info{
          padding-left: 10px;
          h3{
            font-size: 18px;
          }
          span{
            font-size: 10px;
          }
        }
      }
    }
  }
}


