@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.CalcWrapper {
  margin: 135px 0 0 0;
  width: calc(100% - 370px);
  position: relative;
  left: 332px;
  .AddressWrapper{
    padding-left: 40px;
  }
  .ContentWrapper {

    .CalcMainContent {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      height: 680px;
      margin-bottom: 100px;

      .background-block {
        background-color: $brandColor;
        height: 254px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        .text-block {
          width: 30%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin: 52px 30px 0 0;
        }
        .title{
          color:#FEFEFE;
          font-size: 40px;
          font-weight: 700;
          line-height: 52px;
          letter-spacing: 0.03em;
          text-align: left;
          margin-bottom: 16px;

        }
        .description{
          color:#FEFEFE;
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.03em;
          text-align: left;

        }
      }

      .LeftSide {
        display: flex;
        flex-direction: column;
        width: 50%;
        background-color: white;
        box-shadow: 3px 10px 37px rgba(0, 0, 0, 0.08);
        position: absolute;
        padding: 40px 65px;
        top: 64px;
        left: 9%;

        .calc-title {
          margin-bottom: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 40px;
          font-size: 23px;
          font-weight: 700;
          line-height: 34px;
          letter-spacing: 0.03em;
          text-align: left;


          .bank-icon-wrapper {
            background-color: $greyBorderColor;
            padding: 4px 12px;
            border-radius: 4px;

            .bank-icon {
              height: 100%;
            }
          }
        }

        .custom-input-group {
          margin-bottom: 20px;

          input {
            padding: 12px;

            &:focus {
              box-shadow: none !important;
              outline: none;
              border: 1px solid $brandColor;
            }
          }

          .ant-select-selector {
            height: 48px;
            display: flex;
            align-items: center;

            .ant-select-focused {
              box-shadow: none !important;
              outline: none;
              border: 1px solid $brandColor;
            }
          }
        }

        .fields-horizontal {
          display: flex;
          justify-content: space-between;

          .custom-input-group {
            width: 54%;
            &:first-child {
              flex-grow: 1;
              margin-right: 10px;
            }

            &:last-child {
              width: 42%;
            }
            label{
              height: 20px;
              overflow: hidden;
            }
          }
        }

        .btnsWrapper {
          display: flex;
          margin-top: 10px;
          justify-content: space-between;

          .PrimaryButton {
            width: calc(50% - 5px);
            padding: 0 25px;
            color: white;
            .title{
              font-size: 15px;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: 0.03em;
              text-align: left;

            }
            &.dark{
              color: black;
            }
            &:hover{
              background-color:$hoverColor;
            }
          }
        }

      }

      .RightSide {
        width: 30%;
        padding: 22px 20px;
        border: 1px solid #DDDEDF;
        position: absolute;
        right: 7%;
        bottom: 40px;
        .calc-title{
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0.03em;
          text-align: left;
          color: #282825;
        }

        .result-box {
          width: 100%;
          margin-top: 46px;
          .result-box-row-title{
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.03em;
            text-align: left;

          }

          .result-box-row {
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
            font-weight: 500;

            .result-box-row-text {
              display: flex;
              align-items: center;
              font-weight: 700;
            }

            svg {
              width: 18px;
              height: 18px;

              path {
                fill: #444;
              }
            }
          }

          .result {
            margin-top: 40px;
            padding: 12px;
            background-color: $brandColor;
            border-radius: 6px;

            .result-box-row {
              font-size: 18px;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .results-table {
      width:calc(100% - 15%);
      margin: 0 auto 60px 8.8%;
      overflow-x: auto;


      table, td, th {
        border: 1px solid #c3c3c3;
      }

      table {
        border-radius: 4px;
        border-collapse: collapse;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        max-width: 100%;

        thead {
          width: 100%;
          background-color: $brandColor;

          th {
            padding: 10px;
          }
        }

        tbody {
          width: 100%;

          td {
            padding: 4px 10px;
            font-weight: 500;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px){
    .RightSide{
      width: 36% !important;
      right: 2% !important;
      bottom: 20px!important;
      .result-box-row{
        .result-box-row-title{
          font-size: 12px !important;
        }
      }
    }
    .PrimaryButton{
      .title{
        font-size: 12px;
      }
    }
  }
  @media all and (max-width: 1000px) {
    width: 100%;
    left: 0;
    .ContentWrapper {
      .results-table{
        width: 90% ;
        margin: 0 4%;
      }
      .AddressWrapper{
        padding-left: 4%;
      }
      .HeaderWrapper {
        .item-card {
          padding-right: $mainPadding;
          margin-right: 0;
        }
      }

      .CalcMainContent {
        height: unset;
        flex-direction: column;
        padding-bottom: 60px;
        .background-block{
          justify-content: flex-start;
          .text-block{
            width: 80%;
            margin: 20px 4%;
          }
        }

        .LeftSide {
          width: 90%;
          margin:100px 4% 30px 4%;
          padding: 30px 30px;
          left: unset;
          position: relative;
        }

        .RightSide {
          width: 90% !important;
          position: unset;
          margin: 80px 4% 0 4%;

          .result-box {
            margin-top: 25px;
          }
        }
      }
    }
  }

  @media all and (max-width: 600px) {
    .ContentWrapper {
      .custom-input-group{
        width: 100%!important;
      }

      .CalcMainContent {
        padding-bottom: 30px;

        .LeftSide {
          .custom-input-group {
            margin-bottom: 4px;

            input {
              padding: 8px 12px;
            }

          }

          .fields-horizontal {
            flex-direction: column;

            .custom-input-group {
              &:last-child {
                width: 100%;
              }

              &:first-child {
                margin-right: 0;
              }
            }
          }

          .btnsWrapper {
            .PrimaryButton {
              height: 40px;
              font-size: 14px;
            }
          }
        }

        .fields-horizontal {

          .custom-input-group {
            .ant-select-selector {
              margin-bottom: 10px;
              height: 40px;
            }
          }
        }

        .RightSide {
          .result-box {
            padding: 12px 8px;

            .result-box-row {
              font-size: 14px;
            }

            .result {
              .result-box-row {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}
